import React, { FunctionComponent, useEffect } from "react";
import { ArrowRight } from "../../images/icons/arrow-right-two";
import { Link } from "../Link";
import "./styles.scss";
import Accessibility from "../Accessibility";

export interface CtaBlockInterface {
  ctaLabel: string;
  ctaURL: string;
  openInNewWindow: boolean;
  theme: {
    name: string;
  };
}

const CtaBlock: FunctionComponent<CtaBlockInterface> = ({ ctaLabel, ctaURL, theme, openInNewWindow }) => {

  const createThemeClassName = (className: string) => {
    return className ? `theme--${className?.replace(" ", "").toLocaleLowerCase()}` : "";
  };

  return (
    <section className={`cta-block ${createThemeClassName(theme?.name)}`} data-testid="cta-block">
      <div className="ball">
        <Link to={ctaURL} className="cta-block-link" external={openInNewWindow}>
          <div>
            {ctaLabel}
            <Accessibility location={ctaURL} openNewWindow={openInNewWindow} />
            <ArrowRight />
          </div>
        </Link>
      </div>
    </section>
  );
};

export default CtaBlock;
